import React from 'react'
import { graphql } from 'gatsby'
import style from './divider.mod.scss'

const Divider = ({ colour = 'primary', modifiers = [] }) => {
  colour &&
    modifiers.push([
      style[`divider--colour-${colour.toLowerCase().replace('-', ' ')}`]
    ])
  return <hr className={[style.divider, ...modifiers].join(' ')} />
}

export default Divider

export const query = graphql`
  fragment Divider on DatoCmsDivider {
    id
    paddingTop
    paddingBottom
    model {
      apiKey
    }
    __typename
  }
`
