import React from 'react'
import { Block, Container, ModuleAreaWithAside } from '@components'

const DatoCmsModuleAreaWithAside = ({
  paddingTop,
  paddingBottom,
  ...props
}) => {
  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Container maxWidth={'large'}>
        <ModuleAreaWithAside {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsModuleAreaWithAside
