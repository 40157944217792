import React from 'react'
import DefaultLogoSvg from '@svgs/logo.svg'
import WeCareNowLogoSvg from '@svgs/wecare-now-logo.svg'
import { Link } from 'gatsby'
import style from './logo.mod.scss'

const Logo = ({ modifiers = [], colour = 'white', type = 'default' }) => {
  colour &&
    modifiers.push(style[`logo--${colour.toLowerCase().replace('-', ' ')}`])
  return (
    <div className={[style.logo, ...modifiers].join(' ')}>
      {type === 'default' ? (
        <Link to="/" aria-label={`Home`}>
          <DefaultLogoSvg />
        </Link>
      ) : (
        <Link to="/wecare-now/" aria-label={`WeCare Now`}>
          <WeCareNowLogoSvg />
        </Link>
      )}
    </div>
  )
}

export default Logo
