import React from 'react'
import { Block, Divider } from '@components'

const DatoCmsDivider = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Divider {...props} />
    </Block>
  )
}

export default DatoCmsDivider
