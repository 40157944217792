import React from 'react'
import { Block, Button, Container } from '@components'

const DatoCmsButton = ({ paddingTop, background, paddingBottom, ...props }) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={background}
    >
      <Container>
        <Button
          {...props}
          buttonStyle={props.colour}
          color={'default'}
          length={'large'}
          size={'large'}
          alignment={props.alignment}
          to={props?.link?.link?.path || props?.link?.url}
        >
          {props.link.displayText}
        </Button>
      </Container>
    </Block>
  )
}

export default DatoCmsButton
