import React from 'react'
import style from './feature-grid.mod.scss'
import { graphql } from 'gatsby'
import { Grid } from '@components'

const FeatureGrid = ({ items }) => {
  return (
    <div className={style['feature-grid']}>
      <Grid columns={3} gridGap={'slim'}>
        {items.map(item => {
          return (
            <div className={style['feature-grid__item']}>
              <img src={item.icon.url} />
              <div>
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            </div>
          )
        })}
      </Grid>
    </div>
  )
}

export default FeatureGrid

export const query = graphql`
  fragment FeatureGrid on DatoCmsFeatureGrid {
    items {
      title
      description
      icon {
        url
      }
    }
    paddingBottom
    paddingTop
    __typename
  }
`
