import React from 'react'
import { Block, Button, Container } from '@components'

const DatoCmsBookingButton = ({
  paddingTop,
  paddingBottom,
  background,
  ...props
}) => {
  return (
    <Block
      gutters
      backgroundColour={background}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Container>
        <Button
          {...props}
          buttonStyle={'secondary'}
          color={'default'}
          length={'large'}
          size={'large'}
          to={props.link.url}
          alignment={props.buttonAlignment}
        >
          {props.displayText}
        </Button>
      </Container>
    </Block>
  )
}

export default DatoCmsBookingButton
