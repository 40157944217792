import React from 'react'
import style from './search.mod.scss'
import { Block, Container, SearchBox } from '@components'

const Search = () => {
  return (
    <div className={style.search}>
      <Block paddingTop={'triple'} paddingBottom={'triple'}>
        <Container maxWidth={'small'}>
          <div className={style.search__heading}>
            <h2>{'What are you searching for?'}</h2>
          </div>
          <SearchBox />
        </Container>
      </Block>
    </div>
  )
}

export default Search
