import React from 'react'
import style from './block-header.mod.scss'
import { Block } from '@components'
import { graphql } from 'gatsby'

const BlockHeader = ({
  heading,
  subHeading,
  gutters = false,
  modifiers = [],
  alignment = 'left'
}) => {
  alignment &&
    modifiers.push([
      style[
        `block-header--alignment-${alignment.toLowerCase().replace(' ', '-')}`
      ]
    ])

  return (
    <Block gutters={gutters}>
      <div className={[style['block-header'], ...modifiers].join(' ')}>
        <h2>{heading}</h2>
        <p>{subHeading}</p>
      </div>
    </Block>
  )
}

export default BlockHeader

export const query = graphql`
  fragment BlockHeader on DatoCmsBlockHeader {
    id
    heading
    subHeading
    alignment
    backgroundColour
    paddingTop
    paddingBottom
    __typename
    model {
      apiKey
    }
  }
`
