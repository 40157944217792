import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './video.mod.scss'
import { Modal } from '@components'

const Video = ({ modifiers = [], thumbnail, width = 'large', video }) => {
  width &&
    modifiers.push(
      style[
        `video--width-${width
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')}`
      ]
    )

  const { gatsbyImageData, alt } = thumbnail

  return (
    <div className={[style.video, ...modifiers].join(' ')}>
      <div className={style.video__wrap}>
        <GatsbyImage
          className={style.video__thumbnail}
          image={gatsbyImageData}
          alt={alt || ''}
        />
        <Modal video={video} />
      </div>
    </div>
  )
}

export default Video

export const query = graphql`
  fragment Video on DatoCmsVideo {
    id
    paddingBottom
    paddingTop
    backgroundColour
    model {
      apiKey
    }
    __typename
    thumbnail {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { fit: "crop", h: "674", w: "1170", q: 60 }
      )
      alt
    }
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
`
