import React from 'react'
import { Block, PathwayCard, Grid } from '@components'

const DatoCmsPathwayCardGrid = ({ paddingTop, paddingBottom, cards }) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={'dark'}
    >
      <Grid columns={3} gridGap={'slim'} centerLastItem partition>
        {cards.map(card => {
          return <PathwayCard {...card} key={card.id} />
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsPathwayCardGrid
