import React from 'react'
import style from './table.mod.scss'
import { graphql } from 'gatsby'

const Table = ({ rows, heading }) => {
  return (
    <div className={style.table}>
      {heading && <h5>{heading}</h5>}
      <div className={style.table__wrap}>
        <table>
          <tbody>
            <tr>
              {rows[0].items.map(item => (
                <th key={`head-row-${item.id}`}>{item.title}</th>
              ))}
            </tr>

            {rows.slice(1).map(row => (
              <tr key={`row-${row.id}`}>
                {row.items.map(item => {
                  const hasIcon = item.icon === 'Tick' || item.icon === 'Cross'

                  let getIcon =
                    hasIcon && item.icon === 'Tick' ? <TickSvg /> : <CrossSvg />

                  return (
                    <td
                      key={`row-item-${item.id}`}
                      className={hasIcon && style.table__has_tick}
                    >
                      {hasIcon ? <div>{getIcon}</div> : item.title}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table

export const query = graphql`
  fragment Table on DatoCmsTable {
    rows {
      id
      items {
        id
        title
        icon
      }
    }
    heading
    id
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    __typename
  }
`

const TickSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
  </svg>
)

const CrossSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
  </svg>
)
