import React, { useRef, useContext } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import style from './search-box.mod.scss'
import SearchContext from '@context/searchContext'
import { navigate } from 'gatsby'
import { Input, Button } from '@components'

const CustomSearchBox = ({ refine }) => {
  const searchInputEl = useRef(null)

  const { createURL, setIsSearchModalOpen } = useContext(SearchContext)

  const handleSubmit = e => {
    e.preventDefault()

    refine(searchInputEl.current.value)
    navigate(`/search-results/`, {
      state: { query: createURL(searchInputEl.current.value) }
    })

    setIsSearchModalOpen(false)
  }

  return (
    <div className={style['search-box']}>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder={'Enter Keyword'}
          ariaLabel={'Search'}
          ref={searchInputEl}
          name={'search'}
        />
        <Button type={'submit'} size={'large'} length={'large'}>
          {'Search'}
        </Button>
      </form>
    </div>
  )
}

const SearchBox = connectSearchBox(CustomSearchBox)

export default SearchBox
