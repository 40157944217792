import React from 'react'
import { graphql } from 'gatsby'
import style from './team-member-card.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const TeamMemberCard = props => {
  const { name, role, image, clinic, description, modifiers = [] } = props
  const { gatsbyImageData, alt } = image

  return (
    <div className={[style['team-member-card'], ...modifiers].join(' ')}>
      <div className={style['team-member-card__image']}>
        <GatsbyImage image={gatsbyImageData} alt={alt || ''} />
      </div>
      <div className={style['team-member-card__content']}>
        {name && <h3 className={style['team-member-card__name']}>{name}</h3>}
        {role && <p className={style['team-member-card__role']}>{role}</p>}
        {clinic?.name && clinic.directions && (
          <a
            className={style['team-member-card__clinic']}
            href={clinic.directions}
            target="_blank"
            rel="noopener noreferrer"
          >
            {clinic.name}
          </a>
        )}
        {description && (
          <div
            className={style['team-member-card__description']}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  )
}

export default TeamMemberCard

export const query = graphql`
  fragment TeamMemberCard on DatoCmsTeamMemberCard {
    name
    role
    description
    clinic {
      id
      name
      directions
    }
    id
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "200", w: "200", fit: "crop", q: 60 }
      )
      alt
    }
  }
`
