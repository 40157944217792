import React from 'react'
import { Block, Iframe, Container } from '@components'

const DatoCmsIframe = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Container>
        <Iframe {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsIframe
