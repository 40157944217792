import React from 'react'
import { AilmentsGrid, Block } from '@components'

const DatoCmsAilmentsGrid = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <AilmentsGrid {...props} />
    </Block>
  )
}

export default DatoCmsAilmentsGrid
