import React from 'react'
import { graphql } from 'gatsby'
import style from './content.mod.scss'
import { Button } from '@components'

const Content = ({ content, link, textAlign, largeListFont, modifiers = [] }) => {
  largeListFont && modifiers.push(`${style.content}--large-list-font`)
  textAlign === 'Centre' && modifiers.push(`${style.content}--center`)
  return (
    <div className={[style.content, ...modifiers].join(' ')}>
      <div className={style.content__wrapper}>
        <div
          className={style.content__body}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {(link?.link?.path || link?.url) && (
        <div className={style.content__button}>
          <Button centered to={link?.link?.path || link?.url}>
            {link.displayText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Content

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    paddingTop
    paddingBottom
    textAlign
    content
    model {
      apiKey
    }
    __typename
  }
`
