import React from 'react'
import { graphql } from 'gatsby'
import style from './clinic-card.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button, Divider } from '@components'

const ClinicCard = ({
  button,
  description,
  directions,
  name,
  image,
  address,
  phone,
  fax,
  hours,
  isAside,
  bookingLink,
  clinicLink,
  modifiers = []
}) => {
  const { gatsbyImageData, alt } = image

  return (
    <div className={[style[`clinic-card`], ...modifiers].join(' ')}>
      {name && !isAside && (
        <div className={style[`clinic-card__header`]}>
          <h3>{name}</h3>
        </div>
      )}
      <div className={style[`clinic-card__body`]}>
        <div className={style[`clinic-card__image`]}>
          <GatsbyImage image={gatsbyImageData} alt={alt || ''} />
        </div>
        <div className={style[`clinic-card__content`]}>
          {description && (
            <div
              className={style[`clinic-card__description`]}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          <Divider />
          <div className={style[`clinic-card__details`]}>
            {address && (
              <div className={style[`clinic-card__address`]}>
                <p className={style[`clinic-card__key`]}>{'Address:'}</p>
                <a href={directions} target="_blank">
                  {address}
                </a>
              </div>
            )}

            {phone && (
              <div className={style[`clinic-card__phone`]}>
                <p className={style[`clinic-card__key`]}>{'Phone:'}</p>
                <a
                  href={`tel:${phone.replace(' ', '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style[`clinic-card__value`]}
                >
                  {phone}
                </a>
              </div>
            )}
            {fax && (
              <div className={style[`clinic-card__fax`]}>
                <p className={style[`clinic-card__key`]}>{'Fax:'}</p>
                <p className={style[`clinic-card__value`]}>{fax}</p>
              </div>
            )}
            {hours && (
              <div className={style[`clinic-card__hours`]}>
                <p className={style[`clinic-card__key`]}>{'Hours:'}</p>
                <div
                  className={style[`clinic-card__value`]}
                  dangerouslySetInnerHTML={{ __html: hours }}
                />
              </div>
            )}
          </div>

          <div className={style[`clinic-card__buttons`]}>
            {bookingLink && (
              <Button to={bookingLink} buttonStyle={'primary'} length={'large'}>
                {'Book Now'}
              </Button>
            )}
            {clinicLink && (
              <div className={style[`clinic-card__more-button`]}>
                <Button
                  to={clinicLink.path}
                  buttonStyle={'primary'}
                  length={'large'}
                >
                  {'More'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicCard

export const query = graphql`
  fragment ClinicCard on DatoCmsClinic {
    id
    name
    description
    address
    phone
    fax
    hours
    bookingLink
    clinicLink {
      path
    }
    directions
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "224", w: "404", fit: "crop", q: 60 }
      )
      alt
    }
  }
`
