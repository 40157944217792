import React from 'react'
import { Accordion, Block } from '@components'

const DatoCmsAccordion = ({
  paddingTop,
  paddingBottom,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Accordion {...props} />
    </Block>
  )
}

export default DatoCmsAccordion
