import React from 'react'
import { Block, BlockHeader } from '@components'

const DatoCmsBlockHeader = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <BlockHeader {...props} />
    </Block>
  )
}

export default DatoCmsBlockHeader
