import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { Block } from '@components'
import style from './search-state-results.mod.scss'

const CustomSearchStateResults = ({ searchResults, hasResults = false }) => {
  const nbHits = searchResults && searchResults?.nbHits

  return (
    <Block paddingBottom={hasResults ? 'single' : 'none'}>
      <div className={style['search-state-results']}>
        {!hasResults && (
          <h2>{`Sorry, your search query did not return any results.`}</h2>
        )}
        {hasResults && (
          <h2>{`Found ${nbHits} ${nbHits > 1 ? 'results' : 'result'} for "${
            searchResults?.query
          }"`}</h2>
        )}
      </div>
    </Block>
  )
}

const SearchStateResults = connectStateResults(CustomSearchStateResults)

export default SearchStateResults
