import React from 'react'
import { graphql } from 'gatsby'
import style from './banner-card.mod.scss'
import { Button } from '@components'

const BannerCard = ({ button, description, image }) => {
  const { url, alt } = image

  return (
    <div className={style['banner-card']}>
      <div className={style['banner-card__image']}>
        <img src={url} alt={alt || ''} />
      </div>
      <div className={style['banner-card__content']}>
        {description && (
          <div
            className={style['banner-card__description']}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {(button?.link?.path || button?.url) && (
          <div className={style['banner-card__button']}>
            <Button
              to={button?.link?.path || button.url}
              buttonStyle={'secondary'}
              length={'large'}
              size={'large'}
            >
              {button.displayText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerCard

export const query = graphql`
  fragment BannerCard on DatoCmsBannerCard {
    id
    image {
      url
      alt
    }
    description
    button {
      ...Link
    }
  }
`
