import React from 'react'
import { Location } from '@reach/router'

const isBrowser = () => typeof window !== 'undefined' && window

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: 'circle(0px at calc(100% - 32px) 48px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40
    }
  }
}

const sidebarWrapper = {
  open: {
    display: 'block'
  },
  closed: {
    display: 'none',
    transition: {
      delay: 1.5
    }
  }
}

const defaultState = {
  isSidebarOpen: false,
  toggleSidebar: () => {}
}

const ThemeContext = React.createContext(defaultState)

const toggleBodyScroll = enable => {
  if (enable) {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
    document.body.style.overflowX = 'hidden'
    document.documentElement.style.overflowX = 'hidden'
  }
}

class ThemeProvider extends React.Component {
  state = {
    isSidebarOpen: false
  }

  toggleSidebar = () => {
    let isSidebarOpen = !this.state.isSidebarOpen
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(isSidebarOpen))

    toggleBodyScroll(isSidebarOpen)

    this.setState({ isSidebarOpen })
  }

  closeSidebar = () => {
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(false))
    toggleBodyScroll(false)
    this.setState({ isSidebarOpen: false })
  }

  openSidebar = () => {
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(true))
    toggleBodyScroll(true)
    this.setState({ isSidebarOpen: true })
  }

  componentDidMount() {
    const lsisSidebarOpen = JSON.parse(
      localStorage.getItem('Theme.isSidebarOpen')
    )

    toggleBodyScroll(lsisSidebarOpen)

    if (lsisSidebarOpen) {
      this.setState({ isSidebarOpen: lsisSidebarOpen })
    }
  }

  render() {
    const { children } = this.props
    const { isSidebarOpen } = this.state
    return (
      <Location>
        {({ location }) => {
          const isWeCareNow = location?.pathname.includes('/wecare-now/')

          return (
            <ThemeContext.Provider
              value={{
                isSidebarOpen,
                toggleSidebar: this.toggleSidebar,
                openSidebar: this.openSidebar,
                closeSidebar: this.closeSidebar,
                isWeCareNow
              }}
            >
              {children}
            </ThemeContext.Provider>
          )
        }}
      </Location>
    )
  }
}
export default ThemeContext

export { ThemeProvider, isBrowser, sidebar, sidebarWrapper, toggleBodyScroll }
