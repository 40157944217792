import React from 'react'
import { Block, ImageWithContentInner } from '@components'

const DatoCmsImageWithContentInner = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <ImageWithContentInner {...props} />
    </Block>
  )
}

export default DatoCmsImageWithContentInner
