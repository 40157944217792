import React from 'react'
import { Block, Image } from '@components'

const DatoCmsImage = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  ...props
}) => {
  return (
    <Block
      gutters
      backgroundColour={backgroundColour}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Image {...props} />
    </Block>
  )
}

export default DatoCmsImage
