import React, { useState } from 'react'
import qs from 'qs'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { VirtualSearchBox } from '@components'

const defaultState = {
  searchState: { query: '' },
  index: null,
  createURL: () => {}
}

const SearchContext = React.createContext(defaultState)

const SearchProvider = ({ children }) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
  const [searchState, setSearchState] = useState({ page: 1, query: '' })

  const index = {
    name: process.env.GATSBY_ALGOLIA_PAGE_INDEX,
    title: process.env.GATSBY_ALGOLIA_PAGE_INDEX
  }

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const createURL = query => {
    const config = {
      query
    }

    return `${qs.stringify(config)}`
  }

  const onSearchStateChange = updatedSearchState => {
    if (isSearchModalOpen) {
      setSearchState(updatedSearchState)
    }
  }

  return (
    <SearchContext.Provider
      value={{
        index,
        searchState,
        setSearchState,
        createURL,
        isSearchModalOpen,
        setIsSearchModalOpen
      }}
    >
      <>
        <InstantSearch
          indexName={index.name}
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
        >
          <VirtualSearchBox defaultRefinement={searchState.query} />
          <Configure hitsPerPage={10} page={searchState.page} />
          {children}
        </InstantSearch>
      </>
    </SearchContext.Provider>
  )
}

export default SearchContext

export { SearchProvider }
