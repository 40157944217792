import React from 'react'
import { Block, Card, Grid } from '@components'

const DatoCmsPageCardGrid = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  pages
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <Grid columns={2} gridGap={'large'}>
        {pages.map(page => {
          return (
            <Card
              heading={page.title}
              horizontalImage={page.horizontalImage}
              description={page.excerpt}
              button={{ url: page.path, displayText: 'More' }}
              horizontal
              contained
              key={page.id}
              buttonStyle={'primary'}
              elevated
            />
          )
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsPageCardGrid
