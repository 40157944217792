import React from 'react'
import { Block, Table } from '@components'

const DatoCmsTable= ({
  paddingTop,
  paddingBottom,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Table {...props} />
    </Block>
  )
}

export default DatoCmsTable
