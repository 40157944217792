import React from 'react'
import { ThemeProvider } from '@context/themeContext'
import { SearchProvider } from '@context/searchContext'
import '@scss/reset.global.scss'
import '@scss/styles.global.scss'
import vhCheck from 'vh-check'
import useWhatInput from 'react-use-what-input'

export const wrapRootElement = ({ element }) => {
  vhCheck({
    cssVarName: 'vh-offset',
    redefineVh: true,
    bind: true
  })

  return (
    <ThemeProvider>
      <SearchProvider>{element}</SearchProvider>
    </ThemeProvider>
  )
}
