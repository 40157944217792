import React from 'react'
import { Pricing, Block } from '@components'

const DatoCmsPricing = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Pricing {...props} />
    </Block>
  )
}

export default DatoCmsPricing
