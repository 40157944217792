import React, { useEffect } from 'react'
import style from './pagination.mod.scss'
import { Block, Divider } from '@components'
import smoothscroll from 'smoothscroll-polyfill'

const Pagination = ({ currentPage, totalPages, setPage }) => {
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const isFirstPage = currentPage === 0
  const isLastPage = currentPage === totalPages - 1

  const lastClasses = [style[`pagination__last`]]
  isLastPage && lastClasses.push(style[`pagination__last--active`])

  const firstClasses = [style[`pagination__first`]]
  isFirstPage && firstClasses.push(style[`pagination__first--active`])

  const handlePagination = page => {
    setPage(page)
  }

  return (
    <Block paddingTop={'single'} paddingBottom={'double'}>
      <div className={style.pagination}>
        <Divider />
        <div className={style.pagination__controls}>
          <button
            className={style.pagination__prev}
            aria-label={'Previous'}
            onClick={() => handlePagination(currentPage - 1)}
            disabled={isFirstPage}
          />
          <div className={style.pagination__numbers}>
            <button
              className={firstClasses.join(' ')}
              onClick={() => handlePagination(0)}
            >
              {1}
            </button>
            {currentPage - 2 >= 0 && (
              <span className={style.pagination__dots}>{'...'}</span>
            )}
            {!isFirstPage && !isLastPage && (
              <button
                className={style.pagination__current}
                aria-label={'Current'}
                onClick={() => handlePagination(currentPage - 1)}
              >
                {currentPage + 1}
              </button>
            )}
            {currentPage + 2 < totalPages && (
              <span className={style.pagination__dots}>{'...'}</span>
            )}
            {totalPages - 1 !== 0 && (
              <button
                className={lastClasses.join(' ')}
                aria-label={'Previous'}
                onClick={() => handlePagination(totalPages - 1)}
              >
                {totalPages}
              </button>
            )}
          </div>
          <button
            className={style.pagination__next}
            aria-label={'Next'}
            onClick={() => handlePagination(currentPage + 1)}
            disabled={isLastPage}
          />
        </div>
      </div>
    </Block>
  )
}

export default Pagination
