import React from 'react'
import { Block, Card, Grid } from '@components'

const DatoCmsCardGrid = ({ paddingTop, paddingBottom, cards, columns }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Grid columns={columns} gridGap={'slim'}>
        {cards.map(card => {
          return <Card key={card.id} {...card} contained bordered />
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsCardGrid
