import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './image-with-content-inner.mod.scss'
import { Button, Content, Image } from '@components'

const ImageWithContentInner = ({ button, heading, content, image }) => {
  return (
    <div className={style[`image-with-content-inner`]}>
      {heading && (
        <div className={style[`image-with-content-inner__heading`]}>
          <h5>{heading}</h5>
        </div>
      )}
      <div className={style[`image-with-content-inner__wrap`]}>
        <div className={style[`image-with-content-inner__content`]}>
          <div className={style[`image-with-content-inner__content_body`]}>
            <Content content={content} />
            {(button?.link?.path || button?.url) && (
              <div className={style[`image-with-content-inner__button`]}>
                <Button
                  to={button?.link?.path || button.url}
                  children={button?.displayText}
                  length={'large'}
                  size={'large'}
                />
              </div>
            )}
          </div>
        </div>
        <div className={style[`image-with-content-inner__image`]}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
        </div>
      </div>
    </div>
  )
}

export default ImageWithContentInner

export const query = graphql`
  fragment ImageWithContentInner on DatoCmsImageWithContentInner {
    button {
      ...Link
    }
    heading
    content
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "490", fit: "crop", w: "740", q: 60 }
      )
      alt
    }
    id
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    __typename
  }
`
