import React from 'react'
import { Block, ClinicCard, Grid } from '@components'

const DatoCmsClinicCardGrid = ({
  paddingTop,
  paddingBottom,
  clinics,
  columns = 3,
  isAside
}) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Grid columns={columns} gridGap={'slim'}>
        {clinics.map(clinic => {
          return <ClinicCard {...clinic} key={clinic.id} isAside={isAside} />
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsClinicCardGrid
