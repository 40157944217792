import React from 'react'
import { Block, Content } from '@components'

const DatoCmsContent = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Content {...props} />
    </Block>
  )
}

export default DatoCmsContent
