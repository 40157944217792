import React from 'react'
import { graphql } from 'gatsby'
import style from './pathway-card.mod.scss'
import { CardLinkWrapper } from './card'

const PathwayCard = ({ link, description, heading, image }) => {
  const { url, alt } = image

  return (
    <CardLinkWrapper to={link?.link?.path || link?.url}>
      <div className={style['pathway-card']}>
        <div className={style['pathway-card__image']}>
          <img src={url} alt={alt || ''} />
        </div>
        <div className={style['pathway-card__content']}>
          {heading && <h4>{heading}</h4>}
          {description && (
            <div
              className={style['pathway-card__description']}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </div>
    </CardLinkWrapper>
  )
}

export default PathwayCard

export const query = graphql`
  fragment PathwayCard on DatoCmsPathwayCard {
    id
    heading
    description
    link {
      ...Link
    }
    image {
      url
      alt
    }
  }
`
