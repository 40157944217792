import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import style from './search-hit-list.mod.scss'
import {
  Container,
  Block,
  SearchStateResults,
  SearchHitListItem,
  SearchPagination
} from '@components'

const CustomSearchHitList = ({ hits = [], query }) => {
  // regex.test returns false if string is empty
  const regex = new RegExp(/^(?!\s*$).+/)
  const hasResults = query && hits.length !== 0 && regex.test(query)

  return (
    <div className={style['search-hit-list']}>
      <Container maxWidth={'medium'}>
        <Block gutters>
          <SearchStateResults hasResults={hasResults} />
          {hasResults && (
            <ul>
              {hits.map(hit => (
                <SearchHitListItem
                  key={hit.objectID}
                  title={hit.title}
                  path={hit.url}
                />
              ))}
            </ul>
          )}
        </Block>
      </Container>
      <Block gutters>
        <SearchPagination hasResults={hasResults} />
      </Block>
    </div>
  )
}

const SearchHitList = connectHits(CustomSearchHitList)

export default SearchHitList
