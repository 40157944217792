import React from 'react'
import { graphql } from 'gatsby'
import style from './list-with-content.mod.scss'
import { Content } from '@components'

const ListWithContent = ({ heading, content, list }) => {
  return (
    <div className={style[`list-with-content`]}>
      <div className={style[`list-with-content__content`]}>
        {heading && (
          <div className={style[`list-with-content__heading`]}>
            <h2>{heading}</h2>
          </div>
        )}
        <Content content={content} />
      </div>
      <div className={style[`list-with-content__list`]}>
        <Content content={list} />
      </div>
    </div>
  )
}

export default ListWithContent

export const query = graphql`
  fragment ListWithContent on DatoCmsListWithContent {
    heading
    content
    list
    id
    model {
      apiKey
    }
    backgroundColour
    paddingBottom
    paddingTop
    __typename
  }
`
