import React from 'react'
import { graphql } from 'gatsby'
import style from './simple-card.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const SimpleCard = props => {
  const { description, heading, image, subheading, modifiers = [] } = props
  const { gatsbyImageData, alt } = image

  return (
    <div className={[style['simple-card'], ...modifiers].join(' ')}>
      <div className={style['simple-card__image']}>
        <GatsbyImage image={gatsbyImageData} alt={alt || ''} />
      </div>
      <div className={style['simple-card__content']}>
        {heading && (
          <h3 className={style['simple-card__heading']}>{heading}</h3>
        )}
        {subheading && (
          <p className={style['simple-card__subheading']}>{subheading}</p>
        )}
        {description && (
          <div
            className={style['simple-card__description']}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  )
}

export default SimpleCard

export const query = graphql`
  fragment SimpleCard on DatoCmsSimpleCard {
    description
    heading
    subheading
    id
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "238", w: "238", fit: "crop", q: 60 }
      )
      alt
    }
  }
`
