import React from 'react'
import { Block, SimpleCard, Grid } from '@components'

const DatoCmsSimpleCardGrid = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  cards
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <Grid columns={3} gridGap={'slim'}>
        {cards.map(card => {
          return <SimpleCard {...card} key={card.id} />
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsSimpleCardGrid
