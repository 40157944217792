import React from 'react'
import style from './ailments-grid.mod.scss'
import { graphql, Link } from 'gatsby'
import { Grid } from '@components'

const AilmentsGrid = ({ items }) => {
  return (
    <div className={style['ailments-grid']}>
      <Grid columns={4} gridGap={'slim'}>
        {items.map(item => {
          return (
            <Link to={item.link.path}>
              <div className={style['ailments-grid__item']}>
                <div className={style['ailments-grid__item-wrap']}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25.8 25.8"
                  >
                    <path
                      fill="currentColor"
                      data-name="Union 22"
                      d="M0 12.9A13 13 0 0 1 12.9 0a13 13 0 0 1 12.9 12.9 13 13 0 0 1-12.9 12.9A13 13 0 0 1 0 12.9Zm2 0a10.8 10.8 0 0 0 11 10.8A10.8 10.8 0 0 0 23.7 13 10.8 10.8 0 0 0 12.9 2.1 10.8 10.8 0 0 0 2.1 12.9Zm8.4 4.3-4-4a1 1 0 0 1 .1-1.5 1 1 0 0 1 1.5 0l3.2 3.3 6.6-6.4a1 1 0 0 1 1.5 0 1 1 0 0 1 0 1.5l-7.4 7a1 1 0 0 1-.8.4 1 1 0 0 1-.7-.4Z"
                    />
                  </svg>
                  <p>{item.title}</p>
                </div>
              </div>
            </Link>
          )
        })}
      </Grid>
    </div>
  )
}

export default AilmentsGrid

export const query = graphql`
  fragment AilmentsGrid on DatoCmsAilmentsGrid {
    items {
      title
      link {
        path
      }
    }
    paddingBottom
    paddingTop
    backgroundColour
    __typename
  }
`
