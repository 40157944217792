import React, { useEffect } from 'react'
import style from './search-modal.mod.scss'
import { motion } from 'framer-motion'
import { Block, Container, Portal } from '@components'
import CloseSvg from '@svgs/close.svg'
import { isBrowser, toggleBodyScroll } from '@context/themeContext'

const windowAvailable = isBrowser()

const appRoot = windowAvailable ? '#___gatsby' : null

const ESCAPE_KEY = 'Escape'

const SearchModal = ({ children, isModalOpen, setIsModalOpen }) => {
  const handleKeydown = e => {
    if (e.key === ESCAPE_KEY) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    toggleBodyScroll(isModalOpen)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isModalOpen, setIsModalOpen])

  const variants = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      transition: { duration: 0.4, ease: [0.83, 0, 0.17, 1] }
    }
  }

  return (
    <>
      {isModalOpen && (
        <Portal selector={appRoot}>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={variants}
            className={style['search-modal']}
          >
            <Container maxWidth={'large'}>
              <Block paddingTop={'double'} paddingBottom={'double'} gutters>
                <div className={style['search-modal__body']}>
                  <div className={style['search-modal__header']}>
                    <h1>{'Search'}</h1>
                    <button
                      className={style['search-modal__close']}
                      aria-label={'Close Modal'}
                      onClick={() => setIsModalOpen(false)}
                    >
                      <CloseSvg />
                    </button>
                  </div>

                  <div className={style['search-modal__inner']}>
                    <Container maxWidth={'small'}>{children}</Container>
                  </div>
                </div>
              </Block>
            </Container>
          </motion.div>
        </Portal>
      )}
    </>
  )
}

export default SearchModal
