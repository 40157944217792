import React, { useState } from 'react'
import style from './menu.mod.scss'
import { Dropdown } from '@components'
import { graphql, Link } from 'gatsby'
import ThemeContext from '@context/themeContext'

const Menu = ({ items, modifiers = [], isWecareNowPage }) => {
  const [activeIndex, setActiveIndex] = useState(-1)

  isWecareNowPage && modifiers.push(style['menu--wecare-now'])

  const handleToggle = curIndex => {
    setActiveIndex(curIndex)
  }

  const handleMouseEnter = curIndex => {
    if (activeIndex !== curIndex) {
      handleToggle(curIndex, false)
    }
  }

  const handleMouseLeave = () => {
    setActiveIndex(-1)
  }

  const transitionSettings = {
    exit: {
      length: 0.5
    },
    entry: {
      delay: 0.2
    }
  }

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? {
          className: [style.menu__link, style['menu__link--parent']].join(' ')
        }
      : null
  }

  const linkConfig = {
    partiallyActive: true,
    className: style.menu__link,
    activeClassName: style['menu__link--current'],
    ...transitionSettings
  }

  return (
    <nav className={[style.menu, ...modifiers].join(' ')}>
      <ul className={style.menu__list}>
        {items.map((item, index) => {
          const { title, path, treeChildren } = item
          return (
            <li
              key={`menu__list--${index}`}
              className={style.menu__item}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {treeChildren.length > 0 ? (
                <Link
                  {...linkConfig}
                  to={path}
                  getProps={isPartiallyActive}
                  onFocus={() => handleToggle(index)}
                  aria-haspopup="true"
                  aria-controls={`menu-${index}-box`}
                  aria-expanded={index === activeIndex}
                  id={`menu-${index}-button`}
                  children={title}
                />
              ) : (
                <Link
                  getProps={isPartiallyActive}
                  {...linkConfig}
                  to={path}
                  children={title}
                />
              )}
              {treeChildren.length > 0 && (
                <Dropdown
                  index={index}
                  items={treeChildren}
                  isOpen={index === activeIndex}
                />
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Menu

export const query = graphql`
  fragment MenuHelpersAllPages on SitePage {
    path
    pageContext
  }
`
