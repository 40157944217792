import React from 'react'
import { Block, Container } from '@components'
import JotformEmbed from 'react-jotform-embed'

const DatoCmsJotform = ({ paddingTop, paddingBottom, src, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Container>
        <JotformEmbed src={`https://form.jotformeu.com/${src}`} />
      </Container>
    </Block>
  )
}

export default DatoCmsJotform
