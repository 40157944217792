import React from 'react'
import style from './iframe.mod.scss'
import { graphql } from 'gatsby'

const Iframe = ({ src }) => {
  return (
    <div className={style.iframe}>
      <iframe src={src} allowFullScreen />
    </div>
  )
}

export default Iframe

export const query = graphql`
  fragment Iframe on DatoCmsIframe {
    src
    id
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    __typename
  }
`
