import React, { useEffect, useState, useContext } from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import { Pagination } from '@components'
import SearchContext from '@context/searchContext'

const CustomSearchPagination = ({ nbPages, hasResults }) => {
  const { searchState, setSearchState } = useContext(SearchContext)
  const [pageIndex, setPageIndex] = useState(0)

  useEffect(() => {
    setSearchState({ ...searchState, page: pageIndex + 1 })
  }, [pageIndex])

  return (
    <Pagination
      totalPages={nbPages === 0 || !hasResults ? 1 : nbPages}
      currentPage={pageIndex}
      setPage={setPageIndex}
    />
  )
}

const SearchPagination = connectPagination(CustomSearchPagination)

export default SearchPagination
