import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import style from './footer.mod.scss'
import { Block, Button, Container, Logo } from '@components'
import Facebook from '@svgs/facebook.svg'
import Instagram from '@svgs/instagram.svg'
import ThemeContext from '@context/themeContext'

const Footer = () => {
  const classes = [style.footer]
  const { datoCmsGlobal } = useStaticQuery(graphql`
    {
      datoCmsGlobal {
        menuLinks: footerLinks {
          ...Link
        }
        menuLinksNow: footerLinksNow {
          ...Link
        }
        facebook
        instagram
      }
    }
  `)
  const {
    menuLinks = [],
    menuLinksNow = [],
    facebook,
    instagram
  } = datoCmsGlobal

  const { isWeCareNow } = useContext(ThemeContext)

  const socialMediaLinks = []
  facebook &&
    socialMediaLinks.push({
      url: facebook,
      id: 'facebook',
      children: <Facebook />
    })
  instagram &&
    socialMediaLinks.push({
      url: instagram,
      id: 'instagram',
      children: <Instagram />
    })

  return (
    <Container maxWidth={'xlarge'}>
      <footer className={classes.join(' ')}>
        <Block gutters>
          <Container maxWidth={'large'}>
            <div className={style.footer__upper}>
              <FooterNav menuLinks={isWeCareNow ? menuLinksNow : menuLinks} />
              <FooterSocialMedia socialMediaLinks={socialMediaLinks} />
            </div>
            <div className={style.footer__lower}>
              <FooterLogo />
              <FooterMeta />
            </div>
          </Container>
        </Block>
      </footer>
    </Container>
  )
}

const FooterNav = ({ menuLinks = [] }) => {
  return (
    <Container maxWidth={'large'}>
      <nav className={style.footer__nav}>
        <ul>
          {menuLinks.map(item => (
            <li key={item.id}>
              <Button
                to={item.link?.path || item.url}
                children={item.displayText}
                unstyled
              />
            </li>
          ))}
        </ul>
      </nav>
    </Container>
  )
}

const FooterSocialMedia = ({ socialMediaLinks }) => {
  return (
    <div className={style['footer__social-media']}>
      <ul>
        {socialMediaLinks.map(item => {
          return (
            <li key={item.id}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.children}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const FooterLogo = () => {
  return (
    <div className={style.footer__logo}>
      <Logo colour={'white'} />
    </div>
  )
}

const FooterMeta = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <div className={style.footer__meta}>
      <div className={style.footer__contact}>
        <a href="tel:033498613">{'03 349 8613'}</a>
        <a href="mailto:info@wecarehealth.co.nz">{'info@wecarehealth.co.nz'}</a>
      </div>
      <div className={style.footer__meta_wrap}>
        <p className={style.footer__copyright}>{`© ${year} WeCare Health.`}</p>
        <p className={style.footer__copyright}>
          {`Designed and Developed by `}
          <Button
            unstyled
            to={'https://www.harveycameron.nz/'}
            children={'Harvey Cameron'}
          />
          {'.'}
        </p>
      </div>
    </div>
  )
}

export default Footer
