import React from 'react'
import style from './ticker.mod.scss'
import { Button } from '@components'

const Ticker = ({ text, link }) => {
  return (
    <div className={style.ticker}>
      <div className={style.ticker__wrap}>
        <p>
          {text}
          <span className={style.ticker__dash}>{'-'}</span>
        </p>
        {(link?.link?.path || link?.url) && (
          <Button unstyled to={link?.link?.path || link?.url}>
            {link.displayText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Ticker
