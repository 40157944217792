import React from 'react'
import style from './search-hit-list-item.mod.scss'
import { Button } from '@components'

const SearchHitListItem = ({ title, path }) => {
  return (
    <li className={style['search-hit-list-item']}>
      <div className={style['search-hit-list-item__headings']}>
        {title && <h3>{title}</h3>}
      </div>
      <div className={style['search-hit-list-item-button']}>
        {path && (
          <Button to={path} size={'large'} length={'large'}>
            {'More'}
          </Button>
        )}
      </div>
    </li>
  )
}

export default SearchHitListItem
