import React from 'react'
import style from './banner-with-cards.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BannerCard } from '@components'

const BannerWithCards = ({ heading, caption, image, cards }) => {
  return (
    <div className={style['banner-with-cards']}>
      <div className={style['banner-with-cards__upper']}>
        <div className={style['banner-with-cards__image']}>
          <div className={style['banner-with-cards__inner']}>
            {image && (
              <GatsbyImage
                className={style['banner-with-cards__gatsby-image']}
                image={image.gatsbyImageData}
                alt={image.alt || ''}
                loading={'eager'}
              />
            )}
          </div>
        </div>
        <div className={style['banner-with-cards__wrap']}>
          <div className={style['banner-with-cards__body']}>
            <div className={style['banner-with-cards__content']}>
              {heading && (
                <h1 className={style['banner-with-cards__heading']}>
                  {heading}
                </h1>
              )}
              {caption && (
                <div
                  className={style['banner-with-cards__caption']}
                  dangerouslySetInnerHTML={{ __html: caption }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style['banner-with-cards__lower']}>
        {cards && (
          <div className={style['banner-with-cards__cards']}>
            {cards.map(({ id, ...card }) => (
              <BannerCard key={id} {...card} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerWithCards
