import React from 'react'
import PropTypes from 'prop-types'
import style from './block.mod.scss'

const Block = ({
  children,
  type,
  id,
  gutters = false,
  backgroundColour = 'None',
  paddingTop = 'None',
  paddingBottom = 'None'
}) => {
  const modifiers = [
    style[
      `block--padding-top-${paddingTop
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    ],
    style[
      `block--padding-bottom-${paddingBottom
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    ],
    backgroundColour &&
      style[
        `block--bg-${backgroundColour
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')}`
      ]
  ]
  gutters && modifiers.push(style[`block--gutters`])

  const config = {
    className: [style.block, ...modifiers].join(' '),
    id,
    children
  }

  switch (type) {
    case 'section':
      return <section {...config} />
    default:
      return <div {...config} />
  }
}

Block.propTypes = {
  children: PropTypes.node.isRequired
}

export default Block
